import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const Privacy = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Polityka prywatności"
        keywords={[`borkowska`, `adwokat`, `gdańsk`, `gdansk`, `kancelaria`]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h3 id="polityka-prywatnosci">Polityka prywatności</h3>
          <ol style={{ 'list-style': 'numeric' }}>
            <li>
              Administratorem strony internetowej www.adwokat-borkowska.pl jest Kancelaria Adwokacka Adwokat Agnieszka Borkowska z&nbsp;siedzibą w&nbsp;Gdańsku, Aleja Grunwaldzka 24 lok. 7, 80 – 229 Gdańsk, NIP: 5833408472, REGON:  387226448.
            </li>
            <li>
              Kontakt do Administratora:
              <ol style={{ 'list-style': 'lower-alpha' }}>
                <li>+48 500–136–402,</li>
                <li>kancelaria@adwokat-borkowska.pl,</li>
                <li>Aleja Grunwaldzka 24 lok. 7, 80 – 229 Gdańsk.</li>
              </ol>
            </li>
            <li>
              Strona internetowa www.adwokat-borkowska.pl wykorzystuje cookies wyłącznie w&nbsp;celach statystycznych.
            </li>
            <li>
              W&nbsp;celu opisanym w&nbsp;pkt 3&nbsp;strona internetowa www.adwokat-borkowska.pl korzysta z&nbsp;serwisu zewnętrznego Google Analytics.
            </li>
            <li>
              Podmiot odwiedzający ma możliwość wyrażenia zgody na wykorzystywanie cookies poprzez zaznaczenie opcji „Zaakceptuj” albo „Odrzuć”.
            </li>
            <li>
              Podmiot odwiedzający może w&nbsp;każdym czasie usunąć cookies za pomocą używanej przez siebie przeglądarki internetowej.
            </li>
          </ol>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    picture: file(relativePath: { eq: "DOR_9750popr.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `

const PrivacyPage = props => (
  <StaticQuery
    query={indexQuery}
    render={data => <Privacy location={props.location} data={data} {...props} />}
  />
)
export default PrivacyPage
